<template>
  <div class="bg-white px-1 rounded-lg">
    <h2 class="font-weight-bolder font-medium-5 pt-2">
      {{ $t('Transfer Schedule') }}
    </h2>
    <div>
      <label class="font-small-4 font-weight-bolder pl-1 mt-2">{{ $t('Asset(s) allocation') }}</label>
      <b-form-group
        v-slot="{ ariaDescribedby }"
        content-cols-md="12"
      >
        <div class="d-flex justify-content-between">
          <b-form-radio
            v-for="field in ['spec_date_and_time', 'as_soon_as_possible']"
            :key="permanentTransferCreateFields[field].label"
            v-model="inventoryPermanentTransfer[field]"
            class="w-50 ml-1 d-flex"
            :aria-describedby="ariaDescribedby"
            name="assetsAllocation"
            :value="permanentTransferCreateFields[field].value"
            :disabled="!$can(ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE.action,ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE.subject)"
            @change="radioButtonHandler(permanentTransferCreateFields[field].value)"
          >
            {{ $t( permanentTransferCreateFields[field].label ) }}
          </b-form-radio>
        </div>
      </b-form-group>
    </div>
    <b-row cols-md="12">
      <component
        :is="permanentTransferCreateFields[field].type"
        v-for="field in ['scheduled_dispatch', 'transfer_time']"
        :key="field"
        v-model="inventoryPermanentTransfer[field]"
        class="h-auto pl-1"
        height="39px"
        style="width: 49%"
        v-bind="getProps(field, permanentTransferCreateFields)"
        :is-editable="isDisabled"
        :is-only-button="true"
        :config="{allowInvalidPreload: true, defaultDate: inventoryPermanentTransfer['scheduled_dispatch'], minDate: 'today'}"
      >
        <template #input-extra-el>
          <b-form-input
            id="transfer_time"
            v-model="inventoryPermanentTransfer['transfer_time']"
            type="time"
          />
        </template>
      </component>
    </b-row>
    <b-row cols-md="12">
      <component
        :is="permanentTransferCreateFields[field].type"
        v-for="field in ['receiving_warehouse', 'purpose']"
        :key="field"
        v-model="inventoryPermanentTransfer[field]"
        class="h-auto pl-1"
        style="width: 49%"
        :disabled-option-by-id="currentWarehouse.id"
        v-bind="getProps(field, permanentTransferCreateFields)"
        :is-editable="!$can(ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE.action,ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE.subject)"
      />
    </b-row>
  </div>
</template>
<script>
import { getProps } from '@core/utils/utils'
import {
  BCol, BFormGroup, BFormInput, BFormRadio, BRow,
} from 'bootstrap-vue'
import config from '../../../create/createTransferByAssetID/config'
import mainConfig from '../../../config'

export default {
  components: {
    BFormInput,
    BFormRadio,
    BRow,
    BFormGroup,
    BCol,
  },
  data() {
    return {
      isDisabled: false,
    }
  },
  computed: {
    inventoryPermanentTransfer() {
      return this.$store.state[this.MODULE_NAME].inventoryPermanentTransferFormByAsset
    },
    currentWarehouse() {
      return this.$store.state.app.warehouse
    },
  },
  methods: {
    radioButtonHandler(radioValue) {
      if (radioValue === this.AS_SOON) {
        this.handleSoonPossible(this, radioValue, payload => {
          const {
            transferTime, scheduledDispatch,
          } = payload
          this.isDisabled = true
          this.inventoryPermanentTransfer.transfer_time = transferTime
          this.inventoryPermanentTransfer.scheduled_dispatch = scheduledDispatch
          this.inventoryPermanentTransfer.as_soon_as_possible = radioValue
        })
      } else {
        this.isDisabled = false
        this.inventoryPermanentTransfer.scheduled_dispatch = new Date()
        this.inventoryPermanentTransfer.transfer_time = null
        this.inventoryPermanentTransfer.spec_date_and_time = radioValue
      }
    },
  },
  setup() {
    const {
      MODULE_NAME, AS_SOON, SPECIFIC_DATE, permanentTransferCreateFields, ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE,
    } = config()

    const { handleSoonPossible } = mainConfig()

    return {
      MODULE_NAME,
      permanentTransferCreateFields,
      ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE,
      AS_SOON,
      SPECIFIC_DATE,
      getProps,
      handleSoonPossible,
    }
  },
}
</script>
<style lang="scss">
.custom-radio-group-input {
  padding-left: 10px !important;
  margin-bottom: 8px !important;

  & .custom-control-label {
    position: relative !important;
    width: 100% !important;

    &::before {
      left: 95.2% !important;
    }
  }
}
</style>
